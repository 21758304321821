import React from "react";
import * as styleButton from "../styles/button.module.css"
import { useEffect, useState } from "react";

/**
 * Importante, primer hijo es el elemento a expandir verticalmente, el segundo el elemento a ocultar
 */
const ReadMore = (props) => {

    const [children, setChildren] = useState(props.children); 

    const handlerClick = () => {
        setChildren(children.map((data, index) => (
            React.cloneElement(data, {style: (index === 0 ? {height: "fit-content"} : {display: "none"})})
        )))
    }

    return (
        <div style={props.style} onClick={handlerClick}>
            {children}
        </div>
    )
}

export { ReadMore };