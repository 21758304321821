import React from "react";
import * as styleButton from "../styles/button.module.css"
import { useEffect, useState } from "react";

/**
 * Boton con retorno al principio del documento y efecto fade relativo al scroll de la pagina
 * @param {Number} posY
 * @param {Number} posX
 * @param {Number} size
 * @param {Number} minScroll
 * @param {Number} factor 0.0 .. 1.0
 */
const Returntop = (props) => {
    if(props.posY === undefined) props = {...props, posY: 50};
    if(props.posX === undefined) props = {...props, posX: 50};
    if(props.size === undefined) props = {...props, size: 50};
    if(props.size < 0) props = props.size = 50;
    if(props.minScroll === undefined) props = {...props, minScroll: 200};
    if(props.minScroll < 0) props = props.minScroll = 200;
    if(props.factor === undefined) props = {...props, factor: 1.0};
    if(props.factor < 0.0) props = props.factor = 0.0;
    if(props.factor > 1.0) props = props.factor = 1.0;

    const place = {
        width: props.size+'px',
        height: props.size+'px',
        position: "fixed",
        bottom: props.posY+'px',
        right: props.posX+'px'
    }

    const style = {
        padding: "0px",
        borderRadius: props.size/2,
        textAlign: "center",
        lineHeight: props.size+'px',
        zIndex: 200
    }

    const [fadeValue, setFadeValue] = useState(0);
    const [displayValue, setDisplayValue] = useState("none");

    const fade = {
        opacity: fadeValue,
        display: displayValue
    }

    useEffect(() => {

        const handleScroll = event => {
            const currentScrollY = window.scrollY;
            
                if (currentScrollY > 200) {
                    setFadeValue(100 / (window.document.body.offsetHeight - window.innerHeight - props.minScroll) * (currentScrollY - props.minScroll) / 100);
                    setDisplayValue("block");
                }else{
                    setFadeValue(0);
                    setDisplayValue("none");
                }
            
          };

        window.addEventListener('scroll', handleScroll);

        return() => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const handlerClick = () => {
        window.scroll({
            top: 0,
            left: 0,
            behaviour: 'smooth'
          });
    }

    return (
        <div className={styleButton.contactButton} style={{...place, ...style, ...fade}} onClick={handlerClick}>
            <div className={styleButton.returnImage} style={{width: props.size, height: props.size, backgroundSize: (props.factor*100)+"%" }}/>
        </div>
    )
}

export { Returntop };