// extracted by mini-css-extract-plugin
export var acercaNosotrosContainer = "index-module--acercaNosotrosContainer--PPSVs";
export var acercaNosotrosExtenderTexto = "index-module--acercaNosotrosExtenderTexto--laNd2";
export var acercaNosotrosTexto = "index-module--acercaNosotrosTexto--G7tTJ";
export var buttonConducta = "index-module--buttonConducta--EDKPL";
export var buttonsContainerGrupoGof = "index-module--buttonsContainerGrupoGof--pCSgF";
export var containerEmpresa = "index-module--containerEmpresa--+dqAU";
export var contenidoGrupoGof = "index-module--contenidoGrupoGof--CjG6X";
export var downloadImage = "index-module--downloadImage--RSnCd";
export var extendido = "index-module--extendido--IA91b";
export var flexColumn = "index-module--flexColumn--rW7fw";
export var flexRow = "index-module--flexRow--8AZKL";
export var grupoGofContainer = "index-module--grupoGofContainer--jkBUZ";
export var imageContainerEmpresas = "index-module--imageContainerEmpresas--puF3x";
export var imageEmpresa = "index-module--imageEmpresa--TnMkJ";
export var middleContainerEmpresas = "index-module--middleContainerEmpresas--dVSHP";
export var textContent = "index-module--textContent--aYfwa";
export var title = "index-module--title--uWwWM";