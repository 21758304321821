import React, { useState, useEffect, useRef } from "react"
import * as style from "../styles/carrusel.module.css"
/*
import VectorIzquierda from "../images/assets/VectorIzquierda.svg"
import VectorDerecha from "../images/assets/VectorDerecha.svg"
*/
import respeto from "../images/assets/valoresicons/respeto_s.webp"
import humildad from "../images/assets/valoresicons/humildad_s.webp"
import constancia from "../images/assets/valoresicons/constancia_s.webp"
import orgullo from "../images/assets/valoresicons/orgullo_s.webp"
import lealtad from "../images/assets/valoresicons/lealtad_s.webp"


import { useTranslation } from "gatsby-plugin-react-i18next"

// Carrusel, vista para desktop con 4 Valores y vista para móviles 1 Valor
const Carrusel = ({ID, contenido}) => {

    const { t } = useTranslation();

    const [matches, setMatches] = useState(typeof window !== "undefined" ? window
        .matchMedia("(min-width: 851px)").matches : true);
    const [id, setId] = useState(0);
    const carruselRef = useRef(null);

    /* Detectar sí la resolución ha cambiado */
    useEffect(() => {
        matches && setId(0);        
        window
        .matchMedia("(min-width: 851px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, [carruselRef.current !== null ? carruselRef.current.clientWidth : 0]);


    //Me guardo el tamaño de mi colección para saber cuando el carrusel debe parar.
    var maxIndex = contenido.length;

    // Increment comprueba para desktop que sí el index llega (id + 3 === índice máximo) y en móviles (id + 1 === índice máximo)
    function increment() {
        if (id + (matches ? 5 : 1) !== maxIndex) setId(id + 1)
    }

    // Decrement si llega al mínimo que es el 0 parar de restar
    function decrement() {
        if (id - 1 >= 0) setId(id - 1)
    }

    /*
    const ImageVectorIzquierda = {
        backgroundImage: `url(${VectorIzquierda})`
    }

    const ImageVectorDerecha = {
        backgroundImage: `url(${VectorDerecha})`
    }
    */

    // Inicializacion de objetos respresentando los iconos de forma local
    const respetoicon = {
        backgroundImage: `url(${respeto})`
    }
    const humildadicon = {
        backgroundImage: `url(${humildad})`
    }
    const constanciaicon = {
        backgroundImage: `url(${constancia})`
    }
    const orgulloicon = {
        backgroundImage: `url(${orgullo})`
    }
    const lealtadicon = {
        backgroundImage: `url(${lealtad})`
    }

    // Array de iconos para ser usados dentro de la funcion map()
    const icons = {
        "respeto" : respetoicon,
        "humildad": humildadicon,
        "constancia": constanciaicon,
        "orgullo": orgulloicon,
        "lealtad": lealtadicon
    }
    
    return (


        <div className={style.containerCarrusel} ref={carruselRef}>
            {/* Flecha hacia la Izquierdae */}
            <div className={`${style.containerImage} ${style.carruselflecha} ${id === 0 ? {} : style.Izquierda}`} role="presentation" onClick={() => decrement()} onKeyDown={() => decrement()}/>
            {/* Contenido del Carrusel */}
            <div className={style.carrusel}>
                {contenido.map((data, index) => (
                    /* Compruebo que si es vista desktop compruebo tres id diferentes para imprimir tres en pantalla, si es para móviles solo imprimo el elemento con la id = index*/
                    <div key={index} className={style.item} style={id === index || (matches ? (id + 1 === index || id + 2 === index || id + 3 === index || id + 4 === index) : false) ? {display: "flex"} : {display:"none"}}>
                        {/*<img className={style.zoominoutbox} src={t(`Index.${ID}.${data.valor}.icon`)} alt=""/>*/}
                        <div className={style.zoominoutbox} style={icons[t(`Index.${ID}.${data.valor}.icon`)]} />
                        <h2 style={{width: "100%"}}>{t(`Index.${ID}.${data.valor}.title`)}</h2>
                        <p>{t(`Index.${ID}.${data.valor}.text`)}</p>
                    </div>
                ))}
            </div>
            {/* Flecha hacia la Derecha */}
            <div className={`${style.containerImage} ${style.carruselflecha} ${id + (matches ? 5 : 1) === maxIndex ? {} : style.Derecha}`} role="presentation" onClick={() => increment()} onKeyDown={() => increment()}/>
        </div>

        
    )
}

export default Carrusel